import React from "react"

const BookSlider = ({ booksDataForSlider }) => {
    return (
        <div className="scrolling-wrapper">
            <div className="card">
                {booksDataForSlider.map((x, index) => 
                    <a rel="noopener noreferrer" target="_blank" href={x.url} key={index}>
                        <img src={x.img} alt="cover"/>
                    </a> 
                )}
            </div>

        </div>
    )
}

export default BookSlider